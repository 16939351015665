import { Injectable, DestroyRef, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router, NavigationEnd } from '@angular/router'
import { Location } from '@angular/common'
import { filter } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NavigationService {
  readonly destroy = inject(DestroyRef)
  private history: string[] = []
  hasError = false

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroy),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        if (
          event instanceof NavigationEnd &&
          event.urlAfterRedirects !== '/404' &&
          event.url === event.urlAfterRedirects
        ) {
          this.history.push(event.urlAfterRedirects)
        }
      })
  }

  back(): void {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl('/')
    }
  }

  get showBackButton(): boolean {
    return this.history.length > 0
  }
}
